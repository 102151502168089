import { currencies } from '@/models/Currencies'
import Decimal from 'decimal.js'
import StatementListItemModel from '@/models/statement/StatementListItemModel'
import { CURRENCY_SHORT_CODES } from '@/constants/auto-exchange'
import MessageModel from '@/models/MessageModel'

const exportExponentialToString = (digits, amountScale) => {
  let exportString = ''

  let str = String(digits)
  // eslint-disable-next-line
  let order = str.replace(/.+e-/gm, '')
  // eslint-disable-next-line
  let mantis = str.match(/(\d*[\.\,]?\d+?)e/)
  let mantisLength = 0
  if (mantis[1].indexOf('.') !== -1) {
    let mantisArray = mantis[1].split('.')
    mantisLength = mantisArray[1].length
  }
  let totalExponentialDigits = Number(order) + Number(mantisLength)
  if (totalExponentialDigits < amountScale) {
    exportString = digits.toFixed(totalExponentialDigits)
  } else {
    exportString = digits.toFixed(amountScale)
  }
  return exportString
}

let convertAmount = amount => {
  let str = String(Number(amount))
  if (str.indexOf('e') !== -1) {
    let numb = str.replace(/.+e-/gm, '')
    amount = exportExponentialToString(Number(amount), Number(numb))
    // amount = amount.toFixed(numb);
  }
  return String(amount)
}

export const transactionConverter = transactions => {
  const transactionsArray = transactions || []
  const getDate = timestamp => {
    let date = new Date(timestamp * 1000)
    let dateObj = {}
    dateObj.day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    dateObj.year = date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear()
    dateObj.month = date.getMonth()
    dateObj.month++
    dateObj.month = dateObj.month < 10 ? `0${dateObj.month}` : dateObj.month
    return `${dateObj.day}.${dateObj.month}.${dateObj.year}`
  }

  let thisUser = localStorage.getItem('user_id')

  let payments = []

  let dateMass = []

  let currencyList = {}

  let balance = []

  let itogBalance = {}

  let preBalance = []

  let timeWork = performance.now()

  for (let i = 0; i < transactionsArray.length; i++) {
    if (transactionsArray[i].user_account_info.user_id !== thisUser) {
      /** --- Собираем платежи --- **/
      let transaction = transactionsArray[i]
      let payment = {}
      for (let j = 0; j < transactionsArray.length; j++) {
        if (transaction.dependency_key === transactionsArray[j].key) {
          payment.myWallet = transactionsArray[j].user_account_info.wallet_id
          payment.myAccount = transactionsArray[j].user_account_info.account_id
          break
        }
      }
      if (transaction.input_output_type === 'OUTPUT') {
        payment.decAmount = new Decimal(transaction.amount.amount)
        payment.ammount = Number(transaction.amount.amount)
        payment.currency = transaction.amount.currency_type
        payment.name = currencies[transaction.amount.currency_type]
        payment.time = transaction.timestamp
        payment.timestamp = transaction.timestamp
        payment.typePayments = 'input'
        payment.userId = transaction.from_user_account_info.user_id
        payment.wallet = transaction.from_user_account_info.wallet_id
      } else if (transaction.input_output_type === 'INPUT') {
        payment.decAmount = new Decimal(transaction.amount.amount)
        payment.ammount = Number(transaction.amount.amount)
        payment.currency = transaction.amount.currency_type
        payment.name = currencies[transaction.amount.currency_type]
        payment.time = transaction.timestamp
        payment.timestamp = transaction.timestamp
        payment.typePayments = 'output'
        payment.userId = transaction.to_user_account_info.user_id
        payment.wallet = transaction.to_user_account_info.wallet_id
        payment.account = transaction.to_user_account_info.account_id
      }
      payment.operationId = transaction.operation_id
      payment.externalTxId = transaction.external_transaction_id
      payment.processType = transaction.process_type
      payment.operationType = transaction.operation_type
      payment.fromUserId = transaction.from_user_account_info.user_id
      payment.toUserId = transaction.to_user_account_info.user_id
      if (!currencyList[payment.currency]) {
        currencyList[payment.currency] = payment.name
      }
      payments.push(payment)
    } else if (transactionsArray[i].user_account_info.user_id === thisUser) {
      /** --- Собираем баланс --- **/
      let temp = {}
      temp.wallet = transactionsArray[i].user_account_info.wallet_id
      temp.account = transactionsArray[i].user_account_info.account_id
      temp.decAmount = new Decimal(transactionsArray[i].amount.amount)
      temp.amount = Number(transactionsArray[i].amount.amount)
      temp.currency = transactionsArray[i].amount.currency_type
      temp.name = currencies[transactionsArray[i].amount.currency_type]
      temp.type = transactionsArray[i].input_output_type
      preBalance.push(temp)
    }
  }

  for (let i = 0; i < preBalance.length; i++) {
    let fl = false
    if (balance.length !== 0) {
      for (let j = 0; j < balance.length; j++) {
        if (balance[j].wallet === preBalance[i].wallet) {
          fl = true
          break
        }
      }
    }
    if (!fl) {
      let walletObj = {}
      walletObj.wallet = preBalance[i].wallet
      walletObj.accounts = {}
      balance.push(walletObj)
    }
  }

  for (let i = 0; i < preBalance.length; i++) {
    for (let j = 0; j < balance.length; j++) {
      if (preBalance[i].wallet === balance[j].wallet) {
        let fl = false
        if (Object.keys(balance[j].accounts).length !== 0) {
          for (let account in balance[j].accounts) {
            if (account === preBalance[i].account) {
              fl = true
              break
            }
          }
        }
        if (!fl) {
          balance[j].accounts[preBalance[i].account] = {}
          balance[j].accounts[preBalance[i].account].account = preBalance[i].account
          balance[j].accounts[preBalance[i].account].amount = []
          break
        }
      }
    }
  }

  for (let i = 0; i < preBalance.length; i++) {
    for (let j = 0; j < balance.length; j++) {
      if (preBalance[i].wallet === balance[j].wallet) {
        for (let a in balance[j].accounts) {
          if (a === preBalance[i].account) {
            let fl = false
            if (balance[j].accounts[a].amount.length !== 0) {
              for (let k = 0; k < balance[j].accounts[a].amount.length; k++) {
                if (balance[j].accounts[a].amount[k].currency === preBalance[i].currency) {
                  fl = true
                  if (preBalance[i].type === 'INPUT') {
                    balance[j].accounts[a].amount[k].decAmount = balance[j].accounts[a].amount[k].decAmount.plus(preBalance[i].amount)
                    balance[j].accounts[a].amount[k].amount += preBalance[i].amount
                  } else if (preBalance[i].type === 'OUTPUT') {
                    balance[j].accounts[a].amount[k].decAmount = balance[j].accounts[a].amount[k].decAmount.minus(preBalance[i].amount)
                    balance[j].accounts[a].amount[k].amount -= preBalance[i].amount
                  }
                  break
                }
              }
            }
            if (!fl) {
              let temp = {}
              temp.amount = 0
              temp.decAmount = new Decimal(0)
              temp.currency = preBalance[i].currency
              temp.currencyName = preBalance[i].name
              if (preBalance[i].type === 'INPUT') {
                temp.decAmount = temp.decAmount.plus(preBalance[i].amount)
                temp.amount += preBalance[i].amount
              } else if (preBalance[i].type === 'OUTPUT') {
                temp.decAmount = temp.decAmount.minus(preBalance[i].amount)
                temp.amount -= preBalance[i].amount
              }
              balance[j].accounts[a].amount.push(temp)
              break
            }
          }
        }
      }
    }
  }

  for (let i = 0; i < balance.length; i++) {
    for (let a in balance[i].accounts) {
      for (let j = 0; j < balance[i].accounts[a].amount.length; j++) {
        if (balance[i].accounts[a].amount[j].amount === 0) {
          balance[i].accounts[a].amount.splice(j, 1)
          j--
        }
      }
    }
  }

  let listCurrencyName = JSON.parse(localStorage.getItem('listCurrencyName'))
  let listTotalAmountTempary = {}

  for (let i = 0; i < listCurrencyName.length; i++) {
    listTotalAmountTempary[listCurrencyName[i]] = new Decimal(0)
  }

  for (let i = 0; i < balance.length; i++) {
    for (let a in balance[i].accounts) {
      for (let j = 0; j < balance[i].accounts[a].amount.length; j++) {
        listTotalAmountTempary[balance[i].accounts[a].amount[j].currencyName] = listTotalAmountTempary[balance[i].accounts[a].amount[j].currencyName].plus(balance[i].accounts[a].amount[j].decAmount)
      }
    }
  }

  for (let val in listTotalAmountTempary) {
    if (listTotalAmountTempary[val].toNumber() !== 0) {
      itogBalance[val] = listTotalAmountTempary[val].toString()
      itogBalance[val] = convertAmount(Number(itogBalance[val]))
    }
  }

  let sortPaymentsMass = (a, b) => {
    if (a.time > b.time) {
      return -1
    } else {
      return 1
    }
  }

  let convertAmountInTransactions = ammount => {
    let str = String(ammount)
    if (str.indexOf('e') !== -1) {
      let numb = str.replace(/.+e-/gm, '')
      ammount = ammount.toFixed(numb)
    }
    return String(ammount)
  }

  payments.sort(sortPaymentsMass)
  for (let i = 0; i < payments.length; i++) {
    let date = getDate(payments[i].time)
    payments[i].time = date
    payments[i].ammount = convertAmountInTransactions(payments[i].ammount)
    if (i === 0) {
      dateMass[i] = {}
      dateMass[i].date = date
      dateMass[i].payments = []
    } else {
      let lengMass = dateMass.length
      let flag = false
      for (let j = 0; j < lengMass; j++) {
        if (dateMass[j].date === date) {
          flag = true
        }
      }
      if (!flag) {
        let obj = {}
        obj.date = date
        obj.payments = []
        dateMass.push(obj)
      }
    }
  }
  for (let i = 0; i < payments.length; i++) {
    let payment = {}
    payment.decAmount = new Decimal(payments[i].ammount)
    payment.ammount = payments[i].ammount
    payment.currency = payments[i].currency
    payment.name = currencies[payments[i].currency]
    if (!payments[i].userId) {
      payment.user = payments[i].account
    } else {
      payment.user = payments[i].userId
    }
    payment.type = payments[i].typePayments
    payment.myWallet = payments[i].myWallet
    payment.myAccount = payments[i].myAccount
    payment.operationId = payments[i].operationId
    payment.externalTxId = payments[i].externalTxId
    payment.processType = payments[i].processType
    payment.timestamp = payments[i].timestamp
    payment.fromUserId = payments[i].fromUserId
    payment.toUserId = payments[i].toUserId
    payment.operationType = payments[i].operationType
    if (payments[i].wallet) {
      payment.wallet = payments[i].wallet
    }
    for (let j = 0; j < dateMass.length; j++) {
      if (dateMass[j].date === payments[i].time) {
        dateMass[j].payments.push(payment)
      }
    }
  }

  timeWork = performance.now() - timeWork
  // console.log(`Время обработки и построения платежей и баланса ${timeWork}`)
  let answer = { dateMass: dateMass, currencyList: currencyList, balance: balance, itogBalance: itogBalance }
  return answer
}

export const transactionConverterV2 = transactions => {
  let timeWork = performance.now()
  const commonBalance = {}
  const walletsObject = {}
  const statementObject = {}
  const messagesObject = {}
  const statementInfoFilters = new Set()
  const sortedTransactions = transactions.sort((a, b) => b.timestamp - a.timestamp)
  sortedTransactions.forEach(item => {
    const {
      user_account_info: { account_id, wallet_id },
      amount: { amount, currency_type },
      input_output_type
    } = item
    if (currency_type === CURRENCY_SHORT_CODES.MessageToken) {
      const message = MessageModel(item)
      if (!messagesObject[message.id]) messagesObject[message.id] = []
      messagesObject[message.id].push(message)
      return
    }
    // Построение выписки по датам
    const statementItem = new StatementListItemModel(item)
    const transactionDate = statementItem.date
    if (!statementObject[transactionDate]) statementObject[transactionDate] = []
    statementObject[transactionDate].push(statementItem)

    // Построение фильтров по столбцу Информация для выписки
    // statementInfoFilters.add(statementItem.description)
    // const statementLocaleString = statementItem.descriptionLocaleKey ? `${statementItem.descriptionLocaleKey}:${statementItem.currencyForLocale}` : statementItem.recipientId
    const statementLocaleString = statementItem.filterValueLocaleCode || statementItem.filterValue || statementItem.recipientId
    statementInfoFilters.add(statementLocaleString)

    // Построение баланса по каждому кошельку и аккаунту
    const currency = CURRENCY_SHORT_CODES[currency_type] || currency_type
    const operationProp = input_output_type === 'OUTPUT' ? 'minus' : 'plus'
    const wallet = walletsObject[wallet_id]
    commonBalance[currency] = new Decimal(commonBalance[currency] || 0)[operationProp](amount).toFixed()
    if (wallet) {
      const account = wallet[account_id]
      if (account) {
        account[currency] = new Decimal(account[currency] || 0)[operationProp](amount).toFixed()
      } else {
        walletsObject[wallet_id][account_id] = {
          [currency]: new Decimal(0)[operationProp](amount).toFixed()
        }
      }
    } else {
      walletsObject[wallet_id] = {
        [account_id]: { [currency]: new Decimal(0)[operationProp](amount).toFixed() }
      }
    }
  })
  timeWork = performance.now() - timeWork
  // console.log(`V2 Время обработки и построения платежей и баланса ${timeWork}`)
  // console.log({ commonBalance })
  return {
    walletsObject,
    statementObject,
    statementInfoFilters,
    messagesObject
  }
}
